<script>
import BaseLisitng from '../../../../share/ListingWithEditMode'
import clientObjectMixin from '../../../share/mixins/clientObjectMixin'
import DynamicBreadcrumb from '@/components/bundles/share/mixins/DynamicBreadcrumb.js'

export default {
  props: {
    service: {}
  },
  mixins: [
    BaseLisitng,
    clientObjectMixin,
    DynamicBreadcrumb
  ],
  data () {
    return {
      headerTitle: { title: 'Stany procesu', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_state_appraisal',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'label', title: 'Opis' },
        { id: 1, data: 'name', title: 'Nazwa' },
        { id: 2, data: 'initial', title: 'Stan początkowy', searchable: false },
        { id: 3, data: 'final', title: 'Stan końcowy', searchable: false },
        { id: 4, data: 'roles', title: 'Role odpowiedzialego', searchable: false, sortable: false },
        { id: 5, data: 'metadata.appraisalBR', title: 'Status BR', searchable: false, sortable: true, render (data) {
            return data ? 'Tak' : ''
          }
        }
      ]
    }
  },
  computed: {
    workflowId () {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).workflows[0].id
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_appraisal_state_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:appraisal:addState`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/workflows/${this.workflowId}/states` }
    }
  }
}
</script>
